import * as React from 'react';

// import Layout from "../components/layout"
// import Seo from "../components/seo"

const NotFoundPage = () => (
  <div className="container pt-header-height">
    {/* <Seo title="404: Not found" /> */}

    <h1 className="mb-sm">404 Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
);

export default NotFoundPage;
